.button {
  height: $button-height;
  color: $text-color;
  border-radius: $border-radius;
  outline: none;
  border: 0;
  padding: 0 1.25rem;
  cursor: pointer;
  text-transform: capitalize;

  &.primary {
    font-weight: bold;
    background: $new-primary-color;
  }

  &.new-primary{
    font-weight: bold;
    background-color: $new-primary-color;
  }

  &.secondary {
    background-color: $secondary-color;
  }

  &.tertiary {
    background-color: $tertiary-color;
  }

  &.transparent {
    background: none;
  }

  &.error {
    font-weight: bold;
    background: $error-color;
  }

  &.icon {
    height: $button-height;
    width: $button-height;
  }

  &:disabled {
    cursor: not-allowed;
    background: $disabled-color;

    &.transparent {
      background: none;
    }
  }
}

.button-primary {
  @extend .button;
  font-weight: bold;
  background: $new-primary-color;
}

.button-primary-with-border{
  @extend .button-primary;
  border-radius: $border-radius;
  border: $border;
}

.button-secondary {
  @extend .button;
  background-color: $secondary-color;
}

.button-secondary-with-border{
  @extend .button-secondary;
  border-radius: $border-radius;
  border: $border;
}

.button-tertiary {
  @extend .button;
  background: $tertiary-color;
}

.button-error {
  @extend .button;
  font-weight: bold;
  background: $error-color;
}

.button-icon {
  @extend .button;
  height: $button-height;
  width: $button-height;
  text-align: center;
}

.button-group {
  @extend .button;
  font: $font-btn-group;

  @media screen and (min-width: $break-lg) {
    font: $font-btn-group-lg;
  }
}

a.button {
  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.grid-action-button {
  @extend .button-secondary;
  font: $font-body;
  height: 28px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: $border-radius;
  background-color: transparent;
  min-width: 11rem;
  &:hover {
    background-color: rgba(255,255,255,.08)
  }
}

.button-back {
  @extend .button-secondary;
  width: 18.75rem;
}
.button-next {
  @extend .button-primary;
  width: 18.75rem;
}

.loading-action-button {
  min-width: 9rem;
}

.zoom-button {
  border: none;
  background-color: transparent;
  font-size: 2rem;
  color: $text-color;
  &:hover {
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
    
    color: var(--theme-gradient-bottom-color);
  }
}

