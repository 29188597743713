$basisCount: 3;
$ordersCount: 12;
$flexCount: 12;

.flex-container {
    display: flex;
    box-sizing: border-box;
    max-width: 100%;
    max-height: 100%;
}

.row {
    @extend .flex-container;
    flex-direction: row;
}

.row-reverse {
    @extend .row;
    flex-direction: row-reverse;
}

.column {
    @extend .flex-container;
    flex-direction: column;
}

.column-reverse {
    @extend .column;
    flex-direction: column-reverse;
}

.row,
.row-reverse {
    &.grow {
        width: 100%;
        height: 100%;
    }

    &.with-gap {
        > *:not(:last-child) {
            margin-right: 1rem;
        }

        &.small {
            > *:not(:last-child) {
                margin-right: 0.5rem;
            }   
        }

        &.large {
            > *:not(:last-child) {
                margin-right: 2rem;
            }   
        }

        &.xl {
            > *:not(:last-child) {
                margin-right: 3rem;
            }   
        }
    }
}

.column,
.column-reverse {
    &.grow {
        width: 100%;
        height: 100%;
    }

    &.with-gap {
        > *:not(:last-child) {
            margin-bottom: 1rem;
        }

        &.small {
            > *:not(:last-child) {
                margin-bottom: 0.5rem;
            }   
        }

        &.large {
            > *:not(:last-child) {
                margin-bottom: 2rem;
            }   
        }

        &.xl {
            > *:not(:last-child) {
                margin-bottom: 3rem;
            }   
        }
    }
}

.row,
.column,
.row-reverse,
.column-reverse {
    display: flex;

    &.start-start {
        @extend .justify-start;
        @extend .align-items-start;
    }

    &.start-center {
        @extend .justify-start;
        @extend .align-items-center;
    }

    &.start-end {
        @extend .justify-start;
        @extend .align-items-end;
    }

    &.center-center {
        @extend .justify-center;
        @extend .align-items-center;
    }

    &.justify-flex-start {
        justify-content: flex-start;
    }

    &.justify-flex-end {
        justify-content: flex-end;
    }

    &.justify-start {
        justify-content: start;
    }

    &.justify-end {
        justify-content: end;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-space-between {
        justify-content: space-between;
    }

    &.justify-space-around {
        justify-content: space-around;
    }

    &.justify-space-evenly {
        justify-content: space-evenly;
    }

    &.align-start {
        align-content: flex-start;
    }

    &.align-end {
        align-content: flex-end;
    }

    &.align-center {
        align-content: center;
    }

    &.align-space-between {
        align-content: space-between;
    }

    &.align-space-around {
        align-content: space-around;
    }

    &.align-space-evenly {
        align-content: space-evenly;
    }

    &.align-stretch {
        align-content: stretch;
    }

    &.align-items-stretch {
        align-items: stretch;
    }

    &.align-items-start {
        align-items: flex-start;
    }

    &.align-items-end {
        align-items: flex-end;
    }

    &.align-items-center {
        align-items: center;
    }

    &.align-items-baseline {
        align-items: baseline;
    }

    &.nowrap {
        flex-wrap: nowrap;
    }

    &.wrap {
        flex-wrap: wrap;
    }

    &.wrap-reverse {
        flex-wrap: wrap-reverse;
    }

    .item {
        flex: 0;

        &.grow { 
            flex: 1;
        }

         // Add flex classes (.f-1, .f-2, ..., .f-n)
        @for $i from 1 through $flexCount {
            &.f-#{$i} {
                flex: $i;
            }
        }

        // Add basis classes (.b-1, .b-2, ..., .b-n)
        @for $i from 1 through $basisCount {
            &.b-#{$i} {
                flex-basis: $i;
            }
        }

        // Add ordering classes (.o-0, .0-1, ..., .o-n)
        @for $i from 0 through $ordersCount {
            &.o-#{$i} {
                order: $i
            }
        }

        &.align-self-center { 
            align-self: center;
        }

        &.align-self-start { 
            align-self: flex-start;
        }

        &.align-self-end { 
            align-self: flex-end;
        }
    }
}