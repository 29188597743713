/*
The DevExtreme Flat button is bugged. This fixes that bug.
This code can be removed after DevExtreme adds the correct dx-button-flat styling.
*/
.dx-button.button-flat {
  background: transparent;
  border: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}

.dx-icon-menu:before,
.dx-icon-user:before {
  color: white;
  margin-bottom: 5px;
}

.dx-context-menu .dx-submenu {
  border: none;
}

.dx-invalid-message > .dx-overlay-content,
.dx-invalid.dx-texteditor .dx-texteditor-buttons-container:after {
  background-color: white;
}

.dx-popup-title.dx-toolbar .dx-button.dx-closebutton {
  background-color: transparent;
}

.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-icon.dx-state-hover {
  background-color: transparent;
}

.dx-button.dx-button-normal.dx-button-mode-contained.dx-state-focused {
  background-color: transparent;
}
