@import 'src/sass/variables.scss';
@import 'src/sass/layout.scss';
@import 'src/sass/button.scss';


.greenhouse-background__page-content {
  width: 115rem;
  height: 65rem;
  border-radius: 0.583rem;
  background-color: $background-color;
}

.greenhouse-background__input-content {
  @extend .scrollable;
  padding: 1rem;
  max-height: 33.25rem;
  overflow-y: auto;
}

.delete-item-button {
  width: 3.1rem;
  height: 3.1rem;
  border: $border-style $border-width $border-color;
  -webkit-box-shadow: 0rem 0rem 0rem $border-width $border-color;
  -moz-box-shadow: 0rem 0rem 0rem $border-width $border-color;
  box-shadow: 0rem 0rem 0rem $border-width $border-color;
  border-radius: $border-radius;
  background-color: $secondary-color;
  color: $text-color;
}
