@import 'src/sass/variables.scss';

body {
  font: $font-body;

  @media screen and (min-width: $break-lg) {
    font: $font-body-lg;
  }
} 

h1 {
  margin: 0;
  padding: 0;
  font: $font-h1;
  @media screen and (min-width: $break-lg) {
    font: $font-h1-lg;
  }
}

h2 {
  margin: 0;
  padding: 0;
  font: $font-h2;
  @media screen and (min-width: $break-lg) {
    font: $font-h2-lg;
  }
}

h3 {
  margin: 0;
  padding: 0;
  font: $font-h3;
  @media screen and (min-width: $break-lg) {
    font: $font-h3-lg;
  }
}

h4 {
  margin: 0;
  padding: 0;
  font: $font-h4;
  @media screen and (min-width: $break-lg) {
    font: $font-h4-lg;
  }
}

.text-gray {
  color: rgba(255, 255, 255, 0.61);
}
