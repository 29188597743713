@import "../variables";
@import "../flex";
@import "../layout";

.button-row {
  @extend .row;
  @extend .center-center;
  margin: 2rem auto;

  > button {
    @extend .button;
    margin: auto 1rem;
  }
}
