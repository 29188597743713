@import "../variables";
@import "../flex";
@import "../layout";
@import "../button";

.page-header {
    @extend .row;
    @extend .start-center;
    @extend .with-gap;

    margin-top: 5.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid $border-color;
    h1 {
      font: $page-header-title-font-size;
    }
}

.page-header-no-top-margin {
    @extend .page-header;
    margin-top: 0;
}

.page-header-no-top-margin h2 {
    margin-top: 1.5rem;
}

.section-header {
    @extend .page-header;
    margin-top: 3.5rem;
    margin-bottom: 0.5rem;
    h2 {
      margin-left: 1rem;
    }
}