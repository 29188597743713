$gridCount: 12;

.grid {
  display: grid;
  &.grid-gap {
    grid-gap: 1rem;

    &.small {
      grid-gap: 0.5rem;
    }

    &.large {
      grid-gap: 2rem;
    }

    &.xl {
      grid-gap: 3rem;
    }
  }
}

@for $i from 1 through $gridCount {
  .grid-cols-#{$i} {
    grid-template-columns: repeat($i, minmax(0, 1fr));
  }
}
