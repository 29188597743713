.rif-lock:before { content: '\e800'; } /* '' */
.rif-mail:before { content: '\e801'; } /* '' */
.rif-performance:before { content: '\e803'; } /* '' */
.rif-production:before { content: '\e805'; } /* '' */
.rif-profile:before { content: '\e806'; } /* '' */
.rif-reminder:before { content: '\e807'; } /* '' */
.rif-revenue:before { content: '\e808'; } /* '' */
.rif-search:before { content: '\e809'; } /* '' */
.rif-settings:before { content: '\e80a'; } /* '' */
.rif-tap:before { content: '\e80b'; } /* '' */
.rif-calendar:before { content: '\e80c'; } /* '' */
.rif-water:before { content: '\e80d'; } /* '' */
.rif-yield:before { content: '\e80e'; } /* '' */
.rif-dashboard:before { content: '\e80f'; } /* '' */
.rif-upload:before { content: '\e810'; } /* '' */
.rif-chat:before { content: '\e811'; } /* '' */
.rif-crop:before { content: '\e812'; } /* '' */
.rif-dash:before { content: '\e813'; } /* '' */
.rif-device:before { content: '\e814'; } /* '' */
.rif-energy:before { content: '\e815'; } /* '' */
.rif-health:before { content: '\e816'; } /* '' */
.rif-index:before { content: '\e817'; } /* '' */
.rif-labour:before { content: '\e818'; } /* '' */
.rif-light:before { content: '\e819'; } /* '' */
.rif-location:before { content: '\e81a'; } /* '' */
.rif-icon_location:before { content: '\e81b'; } /* '' */
.rif-icon_location_in_house:before { content: '\e81c'; } /* '' */
.rif-icon_organisation:before { content: '\e81d'; } /* '' */
.rif-icon_organisation_+:before { content: '\e81e'; } /* '' */
.rif-icon_present:before { content: '\e81f'; } /* '' */
.rif-icon_present_+:before { content: '\e820'; } /* '' */
.rif-icon_recource_percentage:before { content: '\e821'; } /* '' */
.rif-resource_placeholder:before { content: '\e822'; } /* '' */
.rif-icon_recourse_percentage:before { content: '\e823'; } /* '' */
.rif-icon_resource_placeholder:before { content: '\e824'; } /* '' */
.rif-icon_usage:before { content: '\e825'; } /* '' */
.rif-usage:before { content: '\e826'; } /* '' */
.rif-icon_c02:before { content: '\e827'; } /* '' */
.rif-c02:before { content: '\e828'; } /* '' */
.rif-icon_crop:before { content: '\e829'; } /* '' */
.rif-icon_crop_+:before { content: '\e82a'; } /* '' */
.rif-icon_dashboard_leaf:before { content: '\e82b'; } /* '' */
.rif-icon_dashboard_money:before { content: '\e82c'; } /* '' */
.rif-icon_gas:before { content: '\e82d'; } /* '' */
.rif-gas:before { content: '\e82e'; } /* '' */
.rif-icon-arrow-down:before { content: '\e831'; } /* '' */
.rif-icon-arrow-up:before { content: '\e832'; } /* '' */
.rif-hortos-manager:before { content: '\e838'; } /* '' */
.rif-hortos-alert:before { content: '\e839'; } /* '' */
.rif-hortos-grower:before { content: '\e83a'; } /* '' */
.rif-icon_arrow_button:before { content: '\e846'; } /* '' */
.rif-vink_growr:before { content: '\e850'; } /* '' */
.rif-themes_growr:before { content: '\e851'; } /* '' */
.rif-savedviews_growr:before { content: '\e852'; } /* '' */
.rif-manualinput_growr:before { content: '\e853'; } /* '' */
.rif-comparisontool_growr:before { content: '\e854'; } /* '' */
.rif-allindicators_growr:before { content: '\e855'; } /* '' */
.rif-home_growr:before { content: '\e856'; } /* '' */
.rif-weather_growr:before { content: '\e857'; } /* '' */
.rif-climate:before { content: '\e858'; } /* '' */
.rif-icon_chat:before { content: '\e881'; } /* '' */
.rif-icon_tasks:before { content: '\e883'; } /* '' */
.rif-icon_information:before { content: '\e884'; } /* '' */
.rif-icon_calendar:before { content: '\e885'; } /* '' */
.rif-icon_manager:before { content: '\e886'; } /* '' */
.rif-icon_grower:before { content: '\e887'; } /* '' */
.rif-icon_arrow:before { content: '\e888'; } /* '' */
.rif-icon_yield:before { content: '\e889'; } /* '' */
.rif-icon_hamburger:before { content: '\e88a'; } /* '' */
.rif-icon_alerts:before { content: '\e88b'; } /* '' */
.rif-co2-optimizer:before { content: '\e88e'; } /* '' */
