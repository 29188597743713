@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../assets/fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'riddericonfont';
  src: url('../assets/fonts/riddericonfont/riddericonfont.eot');
  src: url('../assets/fonts/riddericonfont/riddericonfont.eot') format('embedded-opentype'), url('../assets/fonts/riddericonfont/riddericonfont.woff') format('woff'),
    url('../assets/fonts/riddericonfont/riddericonfont.ttf') format('truetype'), url('../assets/fonts/riddericonfont/riddericonfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@import './typography';
@import './devextreme-ridder-custom.scss';
@import './devextreme-style-bugfixes.scss';
@import './layout';
@import './riddericonfont';
@import './ionicons';
@import './flex';
@import './form';
@import './button';
@import './components';
@import './components/scrollable.scss';
@import './grid';
@import './onboarding';
@import './handsontable.min.scss';

.clickable {
  cursor: pointer;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}
