//breakpoints
$break-lg: 1440px;
$break-sm: 600px;

//breakpoints for height
$break-iPadMini: 768px;
$break-olderTablets: 800px;
$break-FullHD: 1080px;
$break-FullHD16_10: 1200px;
$break-iPad: 1536px;
$break-iPadPro: 2732px;

//breakpoints for width
$break-iPadMini-w: 1024px;
$break-olderTablets-w: 1280px;
$break-FullHD-w: 1920px;
$break-iPad-w: 2048px;


//colors:
$new-primary-color: #576296;
$input-text-color: black;
$background-color: #161d3a;
$text-color: #ffffff;
$secondary-color: #1f2851;
$tertiary-color: #161d3a;
$error-color: #EE8823;
$warning-color: #EE8823;
$disabled-color: #2d3140;
$inputs-disabled-color:rgba(255, 255, 255, 0.5);
$menu-item-color: #B9BBC7;

$dark-blue-color: #161d3a;
$dark-blue-grey-color: #1f2851;

$primary-gradient-top-color: #5bacf5;
$primary-gradient-bottom-color: #717ef0;
$primary-gradient: linear-gradient(to bottom, $primary-gradient-top-color, $primary-gradient-bottom-color);

$positive-gradient-top-color: #00d1ff;
$positive-gradient-bottom-color: #29ff89;
$positive-gradient: linear-gradient(to bottom, $positive-gradient-top-color, $positive-gradient-bottom-color);

$negative-gradient-top-color: #fff7dc;
$negative-gradient-bottom-color: #f76b1c;
$negative-gradient: linear-gradient(to bottom, $negative-gradient-top-color, $negative-gradient-bottom-color);

$important-gradient-top-color: #6E75EC;
$important-gradient-bottom-color: #190878;
$important-gradient: linear-gradient(to bottom, $important-gradient-top-color, $important-gradient-bottom-color);

$yellow-gradient-top-color:#fffdcc;
$yellow-gradient-bottom-color:#ebc942;
$yellow-gradient: linear-gradient(to bottom, $yellow-gradient-top-color, $yellow-gradient-bottom-color);

$chart-tooltip-gradient-top-color:#6e75ec;
$chart-tooltip-gradient-bottom-color:#190878;
$chart-tooltip-gradient: linear-gradient(to bottom, $chart-tooltip-gradient-top-color, $chart-tooltip-gradient-bottom-color);
$chart-gray-serie-color: #808080bb;

$manager-menu-color: #45A9FE;
$manager-active-menu-color: #318FDE;
$manager-sub-menu-item-color: #2278C1;

$grower-menu-color: #77BA12;
$grower-active-menu-color: #65A209;
$grower-sub-menu-item-color: #589004;

$yield-menu-color: #EE8823;
$yield-active-menu-color: #D1710F;
$yield-sub-menu-item-color:#B46110;

$CO2-optimizer-menu-color: #EBC942;
$CO2-optimizer-active-menu-color: #c9aa2e;
$CO2-optimizer-sub-menu-item-color:#bd9a0e;

$generic-menu-color: #6f7ab1;
$generic-active-menu-color: $new-primary-color;
$generic-sub-menu-item-color:#404b79;

$menu-background-color: #242B51;

$primary-label-color: #A1B0FB;
$primary-icon-color: #A1B0FB;

$fiery-red: #FF2929;

//dimensions:
$button-height: 3.083rem;
$control-height: 3.41rem;

//borders:
$border-style: solid;
$error-message-border-style: dashed;
$border-width: 1px;
$error-message-border-width: 1px;
$border-color: rgba(255, 255, 255, 0.61);
$border-radius: 1.841rem;
$border: $border-width $border-style $new-primary-color;
$projection-grid-border-radius: 0.58rem;
$error-message-border: $error-message-border-style $error-message-border-width $new-primary-color;
$disabled-border-color: rgb(87, 98, 150);
$informational-message-border-style: dashed;
$informational-message-border: $informational-message-border-style $border-width $disabled-border-color;

//font:
$font-h1: bold 1.5rem "Roboto";
$font-h2: bold 1.33rem "Roboto";
$font-h2-normal: normal 1.33rem "Roboto";
$font-h3: bold 1.08rem "Roboto";
$font-h3-normal: normal 1.08rem "Roboto";
$font-h4: normal 0.91rem "Roboto";
$font-underline: bold 1rem "Roboto";
$font-body: normal 1rem "Roboto";
$font-menu-and-forms: normal 0.91rem "Roboto";
$font-btn: normal 1.08rem "Roboto";
$font-btn-group: normal 0.91rem "Roboto";

$font-h1-lg: bold 1.666rem "Roboto";
$font-h2-lg: bold 1.5rem "Roboto";
$font-h3-lg: bold 1.25rem "Roboto";
$font-h4-lg: normal 1.08rem "Roboto";
$font-underline-lg: bold 1.166rem "Roboto";
$font-body-lg: normal 1.083rem "Roboto";
$font-menu-and-forms-lg: normal 1rem "Roboto";
$font-btn-lg: normal 1.25rem "Roboto";
$font-btn-group-lg: normal 1.08rem "Roboto";

//icons-font
$ri-icon-font-size: 2.16rem;
$ri-icon-font-size-lg: 3.33rem;

$ri-icon-big-font-size: 1.5rem;
$ri-icon-big-font-size-lg: 2.25rem;

$ri-icon-md-font-size: 1.5rem;

$ri-icon-big-md-font-size: 1.22rem;

$scrollbar-bottom-spacing: 0.3rem;

$sub-menu-item-font-size: 1.33rem;
$inner-menu-item-font-size: 1rem;

$page-header-title-font-size: bold 2.666rem "Roboto";

$tab-font-size: 1.16rem;
$info-popup-font: normal 1.33rem "Roboto";
$info-popup-text-color: #1F2851;
