@import "./variables";
@import "./types/padding-margin";

html {
  font-size: 12px;
}

body {
  margin: 0px;
  background-color: $background-color;
  color: $text-color;
}

button {
  font: $font-btn;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  @media screen and (min-width: $break-lg) {
    font: $font-btn-lg;
  }
}

.vertical-line {
  height: 1.916rem;
  width: 0.166rem;
  background-color: $border-color;
}

.no-margin {
  margin: 0;
}

.ri-icon {
  font-family: "riddericonfont", "Helvetica", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;

  display: inline-block;
  text-decoration: inherit;
  text-align: center;

  font-variant: normal;
  text-transform: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $ri-icon-font-size;
  @media screen and (min-width: $break-lg) {
    font-size: $ri-icon-font-size-lg;
  }
}

.ri-icon-header{
  @extend .ri-icon;
  font-size: 2rem;
}

.ri-icon-big {
  @extend .ri-icon;
  font-size: $ri-icon-big-font-size;
  @media screen and (min-width: $break-lg) {
    font-size: $ri-icon-big-font-size-lg;
  }
}

.ri-icon-md {
  @extend .ri-icon;
  font-size: $ri-icon-md-font-size;
}

.ri-icon-big-md {
  @extend .ri-icon;
  font-size: $ri-icon-big-md-font-size;
}

.ri-icon-loading {
  @extend .ri-icon;
  font-size: 10rem;
}

.ri-icon-module-loading {
  @extend .ri-icon;
  font-size: 5rem;
}

.positive-gradient-icon {
  color: $positive-gradient-bottom-color;
}

.negative-gradient-icon {
  background: $negative-gradient-bottom-color;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.justify-text-content {
  text-align: justify;
}

.vertical-menu-right-border {
  border-right: solid .5px $border-color;
}

$loadingSpinnerDiameter: 2.33rem;
$loadingSpinnerStrokeWidth: 0.33rem;
$loadingSpinnerStrokeColor:  #29ff89;
$loadingSpinnerOpacity: 0.8;
$loadingSpinnerStrokeStyle: solid;
$loadingSpinnerSpeed: 0.7s;

.loadingSpinner {
  margin-right: 0.66rem;
  margin-left: 0.66rem;
  border: $loadingSpinnerStrokeWidth $loadingSpinnerStrokeStyle
    $loadingSpinnerStrokeColor;
  border-top-color: $secondary-color;
  border-left-color: $secondary-color;
  width: $loadingSpinnerDiameter - ($loadingSpinnerStrokeWidth * 2);
  height: $loadingSpinnerDiameter - ($loadingSpinnerStrokeWidth * 2);
  opacity: $loadingSpinnerOpacity;
  border-radius: 50%;
  animation: loadingSpinner $loadingSpinnerSpeed infinite linear;
  -webkit-animation: loadingSpinner $loadingSpinnerSpeed infinite linear;
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.greyed-out-text {
  color: $border-color;
}

.base-entity-card {
  position: relative;
  margin-right: 2.8rem;
  padding: 2.33rem 1.25rem 2rem 1.25rem;
  background-color: $secondary-color;
  box-sizing: border-box;
}

.menu-icon{
  font-size: 2.2rem;
}

.white-icon{
 color: $text-color;
}

.smaller-menu-icon-with-margin{
  font-size: 1.9rem;
  margin-right: 3px;
}


.tab-container{
  background-color: $dark-blue-grey-color;
}

.half-width-container {

  width: 50%;

  @media screen and (min-width: $break-lg) {
    width: 33.33%;
  }

  @media screen and (max-width: $break-sm) {
    width: 100%;
  }
}

#userMenuBtn {
  margin-bottom: 20px;
}

.half-screen {
  margin: 0 auto;
}

@media (min-width: 1500px) {
  .half-screen {
    width: 50%;
  }

  .half-screen-left {
    width: 50%;
  }
}

#button .button-loading-indicator {
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-left: -10px;
}

.popup-open {
  overflow: hidden;
}

#optionsMenuBtn {
  height: 18px;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.optionsMenuButton {
  height: 18px;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}


//todo: extend this to organizations
.resources-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px;
}

.resources-halfWidthItem {
  width: 50%;
  font-family: "Roboto-Bold", "RobotoFallback", "Helvetica", "Arial", sans-serif;
  margin-top: -0.3rem;
}

.organizations-fullWidthItem,
.resources-fullWidthItem {
  width: 100%;
}

.organizations-rightButton,
.resources-rightButton {
  float: right;
  margin-top: 0.4rem;
}

.bold {
  font-weight: bold;
  font-family: "Roboto", "RobotoFallback", "Helvetica", "Arial", sans-serif;
}

.fieldset-button {
  margin: 20px 20px 0 0;
}

.clear-alignment {
  clear: both;
}


.input {
  background-color: $secondary-color;
  height: 3.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0rem;
  border: $border-style $border-width $border-color;
  color: $text-color;
  outline: none;

  &:disabled {
    opacity: 0.38;
    cursor: not-allowed;
    background: $background-color;
    color: $inputs-disabled-color;
    border: $border-style $border-width $disabled-border-color;
  }
}

.form-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  @media screen and (min-width: $break-lg) {
    margin-top: 1rem;
  }
  button {
    width: 12.16rem;
  }
}

.info-message {
  height: fit-content;
  font: $font-menu-and-forms;
  @media screen and (min-width: $break-lg) {
    font: $font-menu-and-forms-lg;
  }
}

.custom-error {
  color: $error-color;
  font-size: $sub-menu-item-font-size;
  font-weight: 400;
  border: $error-message-border;
  box-sizing: border-box;
  padding: 0.75rem 1.5rem;
  width: fit-content;
  height: fit-content;
}

.custom-error-smaller-font {
  @extend .custom-error;
  font: $font-menu-and-forms-lg;
}


.custom-warning {
  font-size: $sub-menu-item-font-size;
  font-weight: 400;
  border: $error-message-border;
  box-sizing: border-box;
  padding: 0.75rem 1.5rem;
  width: fit-content;
  height: fit-content;
  color: $warning-color;
}

.informational-message {
  font-size: $sub-menu-item-font-size;
  font-weight: 400;
  border: $informational-message-border;
  box-sizing: border-box;
  padding: 0.75rem 1.5rem;
  width: fit-content;
  height: fit-content;
  color: $new-primary-color;
}

.button-loading {
  background: $secondary-color;
  display: flex;
  flex-direction: row;
  font-weight: 300;
}

.greenhouse-background {
  height: 100%;
  width: 100%;
  background: url('../assets/greenhouse-2048.jpeg');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  -o-background-size: 100% 100%, auto;
  -moz-background-size: 100% 100%, auto;
  -webkit-background-size: 100% 100%, auto;
  background-size: 100% 100%, auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ridder-logo {
  margin-top: 5.583rem;
  width: 20rem;
}

.logo {
  width: 30rem;
}

.logo-container {
  padding-bottom: 3rem;
}

.input::-webkit-input-placeholder {
  @extend .input-placeholder;
}

.input-placeholder {
  font: $font-body;
  color: $text-color;
  opacity: 0.41;
}

.performance-indicator-card-group {
  display: flex;
  margin-top: 1.4rem;
}

.icon {
  font-size: 1.65rem;
}

.not-on-target-mark {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem
}

.tree-arrow {
  font-size: 1rem;
  font-weight: 100;
  max-width: 1.5rem;
  min-width: 1.5rem;
  text-align: center;
}

.growr-card{
  padding: 2rem 1rem 2rem 1rem;
  border-radius: 0.583rem;
  box-shadow: 0.125rem 0.125rem 0.5625rem 0 rgba(9, 15, 39, 0.5);
  background-color: $secondary-color;
  box-sizing: border-box;
}

.grower-overview-container {
  background-color: $secondary-color;
}

.period-selector-chevron {
  margin-top: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.grower-pager-position {
  margin-bottom: 2.3rem;
}

.grower-formatted-date {
  margin-top: 0.4rem;
}

.greenhouse-background-pages-fields-container {
  width: 60rem;
  height: fit-content;
  padding-bottom: 3.5rem;
  border-radius: 0.583rem;
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sticky-item {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
}

.full-screen-graph {
  background: $background-color;
}

.page-title {
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid $border-color;
}

.yield-tab {
  background: $menu-background-color;
  border: 1px solid $new-primary-color;
}

input[type='text'].input {
  border: $border;
}

.period-selector-popup-buttons-padding {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.popup-form {
  padding: 1rem 2rem;
  background-color: $background-color;
  height: 20rem;
}

.overview-dropdown{
  min-width: 25rem;
}

ion-icon {
  pointer-events :none;
}

.location-cards-container {
  display: flex;
  overflow-x: auto;
  margin-top: 3.768rem;
  padding-bottom: 0.768rem;
  margin-bottom: $scrollbar-bottom-spacing;
}

.location-cards-container::-webkit-scrollbar {
  height: 1rem;
}

.location-cards-container::-webkit-scrollbar-thumb {
  background: $new-primary-color;
  border-radius: $border-radius;
}

.zone-cards-container{
  @extend .location-cards-container;
}

.crop-cycle-cards-container{
  @extend .location-cards-container;
}

.custom-axis-text{
  white-space: pre;
  fill: rgba(255, 255, 255, 0.87);
  font-size: 16px;
  font-family: Roboto, RobotoFallback, Helvetica, Arial, sans-serif;
  font-weight: 400;
  cursor: default;
  //as the devextreme axis label
}

.status-container{
  margin: 1rem;
  text-align: left;
  overflow-y: scroll;
  max-height: calc(100vh - 25rem);

  &::-webkit-scrollbar {
    width: 1rem;
  }

  &::-webkit-scrollbar-thumb {
      background: $new-primary-color;
      border-radius: $border-radius;
  }

  &::-webkit-scrollbar-track {
      margin-top: 1rem;
      margin-bottom: 1rem;
  }
}

.status-container p {
  margin-right: 1rem;
}