@import "../variables.scss";

input[type="checkbox"] {
    appearance: none;
    cursor: pointer;
    outline: none;
    background: rgba($color: #000000, $alpha: 0);

    border: $border;
    border-radius: $border-radius;

    width: 10px;
    height: 10px;

    &:hover {
        background: $important-gradient;
    }

    &:checked {
        background: $new-primary-color;
    }

    &.large {
        width: 20px;
        height: 20px;
    }
}