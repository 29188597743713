@import "../variables";
@import "../flex";
@import "../layout";

.scrollable {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: $new-primary-color;
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-track {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
