/*
This file defines all types of margin and padding classes.
For instance:
    p-1  (padding: 1rem)
    pt-1 (padding-top: 1rem)
    px-1 (padding-left: 1rem; padding-right: 1rem;)
    m-1  (margin: 1rem)
    mt-1 (margin-top: 1rem)
    mx-1 (margin-left: 1rem; padding-right: 1rem;)

The following options are supported:
    p-n where n=0,1,2,3,4,5 for setting the 'padding' property
    pt-n where n=0,1,2,3,4,5 for setting the 'padding-top' property
    pr-n where n=0,1,2,3,4,5 for setting the 'padding-right' property
    pb-n where n=0,1,2,3,4,5 for setting the 'padding-bottom' property
    pl-n where n=0,1,2,3,4,5 for setting the 'padding-left' property
    px-n where n=0,1,2,3,4,5 for setting the 'padding-left and padding-right' property
    py-n where n=0,1,2,3,4,5 for setting the 'padding-top and padding-bottom' property

    m-n where n=0,1,2,3,4,5 for setting the 'margin' property
    mt-n where n=0,1,2,3,4,5 for setting the 'margin-top' property
    mr-n where n=0,1,2,3,4,5 for setting the 'margin-right' property
    mb-n where n=0,1,2,3,4,5 for setting the 'margin-bottom' property
    ml-n where n=0,1,2,3,4,5 for setting the 'margin-left' property
    mx-n where n=0,1,2,3,4,5 for setting the 'margin-left and margin-right' property
    my-n where n=0,1,2,3,4,5 for setting the 'margin-top and margin-bottom' property
*/

$remAmounts: (0, 1rem, 2rem, 3rem, 4rem, 5rem, 6rem, 7rem, 8rem, 9rem); // Adjust this to include the rem amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $remAmounts {
  $space-char: str-slice(inspect($space), 0, 1);

  @each $side in $sides {
    $side-char: str-slice($side, 0, 1);

    .m#{$side-char}-#{$space-char} { //mt-0, mb-1, etc
      margin-#{$side}: #{$space};
    }

    .p#{$side-char}-#{$space-char} { //pt-0, pb-1, etc.
      padding-#{$side}: #{$space};
    }
  }

  .m-#{$space-char} { //m-0, m-1, etc.
      margin: $space;
  }

  .mx-#{$space-char} { //mx-0, mx-1, etc.
    margin: 0 #{$space};
  }

  .my-#{$space-char} { //my-0, my-1, etc.
    margin: #{$space} 0;
  }

  .p-#{$space-char} { //p-0, p-1, etc.
    padding: $space;
  }

  .px-#{$space-char} { //px-0, px-1, etc.
    padding: 0 #{$space};
  }

  .py-#{$space-char} { //py-0, py-1, etc.
    padding: #{$space} 0;
  }
}
